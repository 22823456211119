import React from 'react';
import styled from 'styled-components';

const Home = ({onConfirm}) => {

  return (
    <Container>
      <Button onClick={onConfirm}>Confirm</Button>
  </Container>
  );
};

export default Home;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  color: #ffffff;
  padding: 0 50px;
`;  



const Button = styled.button`
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  background: #4fab77;
  text-align: center;
  padding: 12px 24px;
  margin-top: 24px;
  border-radius: 20px;
  border: 6px solid black;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  `;