import React from 'react';

const RoundIcon = ({size = "40", color = "black"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" fill="transparent" />
      <circle cx="12" cy="12" r="7" fill={color} />
    </svg>
  );
};

export default RoundIcon;
