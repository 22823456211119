import React from 'react';

const BackIcon = ({size = "40", color = "black"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"
        fill={color}
      />
    </svg>
  );
};

export default BackIcon;
