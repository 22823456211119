import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { API_BASE_URL } from '../config';
import IconButton from '../icons/IconButton';
import BackIcon from '../icons/BackIcon';
import LoadingBar from './LoaderBar';

const Process = ({ src, onBack }) => {

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [meal, setMeal] = useState(''); // State for meal selection
  const [hunger, setHunger] = useState(''); // State for hunger selection

  const imageSrc = src;




  const handleMealChange = (selectedMeal) => {
    setMeal(selectedMeal);
    setFormError(null);
  };

  const handleHungerChange = (selectedHunger) => {
    setHunger(selectedHunger);
    setFormError(null);
  };



  useEffect(() => {

    const progressSteps = [
      ["Sending", 2000],
      ["Received", 2000],
      ["Reading", 3000],
      ["Thinking", 4000],
      ["Recommending", 4000],
      ["Estimating", 2000],
      ["Thinking", 3000],
      ["Hungry?", 6000],
      ["Slow Today!", 6000],
      ["It's still coming.", 10000],
      ["Ok, maybe not.", 3000],
      ["Try again", 10000],
    ];

    let currentStep = 0;

    function startNextStep() {
      if (loading) {
        setLoadingMessage(progressSteps[currentStep][0]);
        currentStep = currentStep + 1 >= progressSteps.length ? 0 : currentStep + 1;
        setTimeout(startNextStep, progressSteps[currentStep][1]);
      }
    }

    startNextStep();

    return () => clearTimeout(startNextStep);

  }, [loading]);


  const handleFetchAnalysis = () => {

/*
    if (!meal) {
      setFormError(`Please select a meal.`);
      return;
    }

    if (!hunger) {
      setFormError(`Please select hunger level.`);
      return;
    }
*/

    setLoading(true);
    setError(null);
    setResponseData(null);

    // let hungerLevel = 0;
    // if(hunger === 'A little bit') hungerLevel = 1;
    // if(hunger === 'Average') hungerLevel = 3;
    // if(hunger === 'Very') hungerLevel = 5;

    // let userData = {
    //   "meal": meal,
    //   "hungerLevel": hungerLevel,
    //   "localTime": new Date().toLocaleTimeString(),
    // };

    // not used for now
    let userData = {
    "meal": "Lunch",
    "hungerLevel": 'Average',
    "localTime": new Date().toLocaleTimeString(),
    }


    const data = JSON.stringify(userData);

    const formData = new FormData();
    formData.append('image', imageSrc);
    formData.append('data', data);

    try {

      fetch(API_BASE_URL + 'experiments/menugpt', {
        method: 'POST',
        body: formData,
      }).then(response => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      })
        .then(json => {
          setLoading(false);
          setResponseData(json);
        })
        .catch(function (error) {
          setError(`Fetch Error.${error}`);
          setLoading(false);
        });

    } catch (error) {
      setError(`Sorry something went wrong, please try again.${error}`);
      setLoading(false);
    }
  }

  let showBackground = true;
  if (!loading && !responseData && !error) showBackground = false;


  // map json response
  let menuItems = [];
  if (responseData) {

    menuItems = responseData.recommendations.map((item, index) => {
      return (
        <div key={index}>
          <h2>{item.itemTitle}</h2>
          <p>{item.gptRational}</p>
          {item.estimatedCalories !== 0 ? <p>Estimated calories: {item.estimatedCalories}</p> : null}
          {(index < responseData.recommendations.length - 1) && <h3> ~ Or ~</h3>}
        </div>
      )
    });
  }

  return (
    <ProcessContainer background={showBackground}>

      {(responseData || error || loading) &&
        <BackContainer>
          <IconButton onClick={onBack} >
            <BackIcon size="30" color="white" />
          </IconButton>
        </BackContainer>
      }

      {loading && 
        <div style={{width: '80%'}}>
          <h3>{loadingMessage}</h3>
          <LoadingBar />
        </div>
      }

      {error && <div>{error}</div>}

      {responseData && <Recommendations>{menuItems}</Recommendations>}

      {!loading && !responseData && !error &&
        <div>

          {/* <h1>Find Healthy Option</h1> */}

          {/* <h2>Meal</h2>
          <ButtonGroup>
            
            <ButtonOption selected={meal === 'Breakfast'} onClick={() => handleMealChange('Breakfast')}>
              Breakfast
            </ButtonOption>
            <ButtonOption selected={meal === 'Lunch'} onClick={() => handleMealChange('Lunch')}>
              Lunch
            </ButtonOption>
            <ButtonOption selected={meal === 'Dinner'} onClick={() => handleMealChange('Dinner')}>
              Dinner
            </ButtonOption>
          </ButtonGroup>

          <h2>Hunger Level</h2>
          <ButtonGroup>
            <ButtonOption
              selected={hunger === 'A little bit'}
              onClick={() => handleHungerChange('A little bit')}
            >
              A little bit
            </ButtonOption>
            <ButtonOption selected={hunger === 'Average'} onClick={() => handleHungerChange('Average')}>
              Average
            </ButtonOption>
            <ButtonOption selected={hunger === 'Very'} onClick={() => handleHungerChange('Very')}>
              Very
            </ButtonOption>
          </ButtonGroup> */}

          <ProcessButton
            onClick={handleFetchAnalysis}>
            Find Healthy Option
          </ProcessButton>
          {formError && <div>{formError}</div>}





        </div>
      }

    </ProcessContainer>
  );
};

export default Process;

const ProcessContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => props.background ? '100%' : '80%'};
  background: ${props => props.background ? '#4fab77' : 'transparent'};
  color: white;
`;

const ProcessButton = styled.button`
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  background: #4fab77;
  text-align: center;
  padding: 12px 24px;
  margin-top: 24px;
  border-radius: 20px;
  border: 6px solid black;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
`;

const Recommendations = styled.div`
  padding: 24px;
  text-align: center;
`;

const BackContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 50px;

`;

const ButtonOption = styled.button`
  display: inline-block;
  padding: 12px 24px;
  margin: 0 8px;
  font-size: 15px;
  border-radius: 15px;
  border: none;
  color: ${props => props.selected ? 'white' : 'black'};
  background: ${props => props.selected ? 'black' : 'white'};

`;

/* EXAMPLE RESPONSE json
{
  recommendations: [
    {
      itemTitle: 'Prawn and Mango Salad',
      gptRational: 'This option is light and nutritious, containing fresh prawns, fruits, and vegetables. It is perfect for a 3/5 hunger level.',
      estimatedCalories: 350
    },
    {
      itemTitle: 'Vegan Buddha Bowl',
      gptRational: 'This dish is a healthy and satisfying option with a mix of quinoa, vegetables, and avocado. It will keep you full without being too heavy.',
      estimatedCalories: 450
    }
  ]
}
*/