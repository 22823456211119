import React from 'react';

const RedoIcon =  ({size = "40", color = "black"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" fill="transparent" />
      <g transform="scale(0.69) translate(5.5 5.5)">
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10.74c-.12 3.09-2.67 5.64-5.76 5.76-3.01.12-5.56-1.99-6.12-4.82-.13-.61.36-1.18.98-1.18.47 0 .88.33.98.8.42 2.07 2.44 3.57 4.72 3.12 1.56-.3 2.82-1.56 3.12-3.12.5-2.56-1.45-4.8-3.92-4.8v1.79c0 .45-.54.67-.85.35l-2.8-2.79c-.2-.2-.2-.51 0-.71l2.79-2.79c.32-.31.86-.09.86.36V6.5c3.39 0 6.13 2.82 6 6.24z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default RedoIcon;

// d2="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10.74c-.12 3.09-2.67 5.64-5.76 5.76-3.01.12-5.56-1.99-6.12-4.82-.13-.61.36-1.18.98-1.18.47 0 .88.33.98.8.42 2.07 2.44 3.57 4.72 3.12 1.56-.3 2.82-1.56 3.12-3.12.5-2.56-1.45-4.8-3.92-4.8v1.79c0 .45-.54.67-.85.35l-2.8-2.79c-.2-.2-.2-.51 0-.71l2.79-2.79c.32-.31.86-.09.86.36V6.5c3.39 0 6.13 2.82 6 6.24z"

