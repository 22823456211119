import React, { useState, useRef } from "react";
import Measure from "react-measure";
import { useUserMedia } from "../hooks/use-user-media";
import IconButton from "../icons/IconButton";
import RedoIcon from "../icons/RedoIcon";
import RoundIcon from "../icons/RoundIcon";
import BackIcon from "../icons/BackIcon";

import {
    Video,
    Canvas,
    Wrapper,
    Container,
    Flash,
    Overlay,
    Toolbar,
    ToolbarItem,
} from "./styles";

const CAPTURE_OPTIONS = {
    audio: false,
    video: {
        advanced: [
            { width: { exact: 2560 } },
            { width: { exact: 1920 } },
            { width: { exact: 1280 } },
            { width: { exact: 1024 } },
            { width: { exact: 900 } },
            { width: { exact: 800 } },
            { width: { exact: 640 } },
            { width: { exact: 320 } }
        ],
        facingMode: "environment"
    }
};

export function Camera({ onCapture, onClear, onBack }) {
    const canvasRef = useRef();
    const canvasHiResRef = useRef();
    const videoRef = useRef();

    const [container, setContainer] = useState({ width: 0, height: 0 });
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
    const [isFlashing, setIsFlashing] = useState(false);


    const mediaStream = useUserMedia(CAPTURE_OPTIONS);

    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }

    const windowWidth = window.innerWidth;
    const windowHeight = Math.min(window.innerHeight - 100, window.innerWidth * 1.5);
    const toolbarHeight = window.innerHeight - windowHeight;

    function handleResize(contentRect) {
        setContainer({
            width: windowWidth, // contentRect.bounds.width,
            height: windowHeight //Math.round(contentRect.bounds.width / aspectRatio)
        });
    }

    function handleCanPlay() {
        setIsVideoPlaying(true);
        videoRef.current.play();
    }

    // alert("W1: " + videoRef.current.videoWidth + " H1: " + videoRef.current.videoHeight + " W2: " + windowWidth + " H2: " + windowHeight);

    function handleCapture() {
        const context = canvasRef.current.getContext("2d");
        const videoHeight = videoRef.current.videoHeight; // 2560;
        const videoWidth = videoRef.current.videoWidth; //1920;

        let scale = windowWidth / videoWidth;

        // drawImage parameters: image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight
        context.drawImage(
            videoRef.current,
            0,
            0,
            videoWidth,
            videoHeight,
            0,
            0,
            windowWidth,
            videoHeight * scale
        );

        //canvasRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);
        setIsCanvasEmpty(false);
        setIsFlashing(true);

        // Hi Res
        const contextHiRes = canvasHiResRef.current.getContext("2d");
        contextHiRes.drawImage(
            videoRef.current,
            0,
            0,
            videoWidth,
            videoHeight,
            0,
            0,
            1440,
            2560
        );
        canvasHiResRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);



    }

    function handleClear() {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setIsCanvasEmpty(true);
        onClear();
    }

    if (!mediaStream) {
        return null;
    }

    return (
        <Measure bounds onResize={handleResize}>
            {({ measureRef }) => (
                <Wrapper>
                    <Container
                        ref={measureRef}
                        maxHeight={windowHeight}
                        maxWidth={windowWidth}
                        style={{
                            height: `${windowHeight}px`
                        }}
                    >




                        <Video
                            ref={videoRef}
                            hidden={!isVideoPlaying}
                            onCanPlay={handleCanPlay}
                            autoPlay
                            playsInline
                            muted
                        />

            
                        

                        <Overlay hidden={!isVideoPlaying} />

                      

                        <Canvas
                            ref={canvasRef}
                            width={windowWidth}
                            height={windowHeight}
                        />

                        <canvas
                            ref={canvasHiResRef}
                            width={1440}
                            height={2560}
                        />


                        <Flash
                            flash={isFlashing}
                            onAnimationEnd={() => setIsFlashing(false)}
                        />

                    </Container>


                    <Toolbar toolbarHeight={toolbarHeight}>
                        <ToolbarItem>
                            <IconButton onClick={onBack} >
                                <BackIcon size="30" color="white" />
                            </IconButton>
                        </ToolbarItem>
                        <ToolbarItem>
                            {isVideoPlaying && (
                                isCanvasEmpty ?
                                    <IconButton onClick={handleCapture} >
                                        <RoundIcon size="80" color="white" />
                                    </IconButton> :
                                    <IconButton onClick={handleClear} >
                                        <RedoIcon size="80" color="white" />
                                    </IconButton>
                            )}
                        </ToolbarItem>
                        <ToolbarItem>
                        </ToolbarItem>
                    </Toolbar>

                </Wrapper>
            )}
        </Measure>
    );
}

