import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 50%;
 
  cursor: pointer;


  &:hover {
    opacity: 0.7;
  }
`;

const IconButton = ({ children, onClick}) => {
  return (
    <StyledButton onClick={onClick} >
      {children}
    </StyledButton>
  );
};

export default IconButton;

// 