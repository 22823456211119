import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import { Camera } from "./camera";
import { Root, Preview, Footer, GlobalStyle } from "./styles";
import Home from "./components/Home";
import Process from "./components/Process";
import Confirm from "./components/Confirm";

function App() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState();

  return (
    <Fragment>
      <Root>

        {!isCameraOpen && !cardImage &&
          <Home
            handleOpenCamera={() => setIsCameraOpen(true)}
          />
        }

        {isCameraOpen && (
          <Camera
            onCapture={blob => setCardImage(blob)}
            onClear={() => setCardImage(undefined)}
            onBack={() => { setIsCameraOpen(false); setCardImage(undefined); }}
          />
        )}

        {cardImage && isCameraOpen && (
          <Confirm
            onConfirm={() => { setIsCameraOpen(false); }}
          />
        )}

        {cardImage && !isCameraOpen && (
          <Process
            src={cardImage}
            onBack={() => { setIsCameraOpen(false); setCardImage(undefined); }} />
        )}

      </Root>
      <GlobalStyle />
    </Fragment>
  );
}

export default App;



// ngrok http 3000
//ngrok start --all --config=%HOMEPATH%\AppData\Local\ngrok\ngrok.yml 


