import React from 'react';
import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
`;

const Loader = styled.div`
  height: 10px;
  width: 100%;
  background: linear-gradient(
    270deg,
    #007bff,
    #007bff,
    #6c757d,
    #28a745,
    #28a745,
    #6c757d
  );
  background-size: 600% 600%;
  animation: ${animate} 8s ease infinite;
  border-radius: 5px;
`;

const LoadingBar = () => <Loader />;

export default LoadingBar;
