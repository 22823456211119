import React from 'react';
import styled from 'styled-components';
import Logo from '../images/logo_black.png'

const Home = (props) => {

  const handleOpenCamera = props.handleOpenCamera;

  return (
    <Container>
      <img src={Logo} alt="Logo" width="100" height="100"/>
      <Title>MenuGPT</Title>
      <Text>Use GPT to help pick the healthiest option on the menu</Text>
      <Button onClick={handleOpenCamera}>Scan Menu</Button>
  </Container>
  );
};

export default Home;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  color: #ffffff;
  padding: 0 50px;
`;  

const Title = styled.h1`
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Text = styled.p`
  font-size: 20px;
  margin-top: 0;
`;


const Button = styled.button`
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  background: #4fab77;
  text-align: center;
  padding: 12px 24px;
  margin-top: 24px;
  border-radius: 20px;
  border: 6px solid black;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  `;